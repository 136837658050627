<template>
  <ak-container card :bodyStyle="{ padding: 0 }" class="ak-card-table">
    <PageHeader></PageHeader>
    <a-card style="width: 96%; margin: 10px auto;margin-bottom: 20px">
      <a-form-model ref="refForm" class="ak-query-filter" :model="queryParams" :colon="false">
        <a-row :gutter="24">
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="员工姓名" prop="userName">
              <a-input v-model="queryParams.userName" style="width1: 250px;" :maxLength="20"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="联系方式" prop="phone">
              <a-input v-model="queryParams.phone" style="width1: 250px;" :maxLength="11"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="操作模块" prop="operatorModule" v-if="false">
              <a-select v-model="queryParams.operatorModule" style="width1: 250px;">
                <a-select-option v-for="(item,index) in moduleList" :value="item.moduleId" :key="index">
                  {{item.moduleName}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="操作时间" prop="dateRange">
              <a-range-picker v-model="queryParams.dateRange" :locale="locale" format="YYYY-MM-DD"/>
            </a-form-model-item>
          </a-col>
          <a-col class="action-btn-main">
            <a-button type="primary" :ghost="true" @click="handleQuery">查询</a-button>
            <a-button style="margin-left: 20px" @click="handleReset">重置</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <a-table :pagination='false'
             :columns="columns"
             :data-source="list"
             :scroll="{ x: '1000px' }"
             :rowKey="(record) => record.id"
             style="width:96%; margin: 0 auto"
    >
        <span slot="operatorTime" slot-scope="text">
          <span class="table-normal">{{$util.parseTime(text, '{y}-{m}-{d} {h}:{i}')}}</span>
        </span>
    </a-table>
    <pagination :total="total" :pageNum.sync="queryParams.page" :pageSize.sync="queryParams.pageSize" @pagination="getList"/>
  </ak-container>
</template>

<script>

  import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
  import * as LoggerApi from '@/api/logger/logger.api'

  const columns = [
    {
      title: '操作时间', dataIndex: 'operatorTime', width: 150, align: 'center',
      scopedSlots: {customRender: 'operatorTime'},
      sorter1: (a, b) => {
        let ta = (a.operatorTime && new Date(a.operatorTime).getTime()) || 0
        let tb = (b.operatorTime && new Date(b.operatorTime).getTime()) || 0
        let ret = ta - tb
        return ret
      },
    },
    {title: '员工姓名', dataIndex: 'userName', width: 110, align: 'center',},
    {title: '员工角色', dataIndex: 'role', width: 110, align: 'center',},
    {title: '联系方式', dataIndex: 'phone', width: 130, align: 'center',},
    // {title: '操作模块', dataIndex: 'operatorModule', width: 200, align: 'center',},
    // {title: '操作记录', dataIndex: 'operatorAction', width: 200, align: 'center',},
    {title: '操作模块', dataIndex: 'modules', width: 200, align: 'center',},
    {title: '操作记录', dataIndex: 'opeAction', width: 200, align: 'center',},
  ]

  export default {
    name: 'OptLogList',
    components: {},
    dictTypes: [
      {dictType: 'module', dataType: Number},
    ],
    data() {
      return {
        locale,
        loading: false,
        queryParams: {
          userName: null,
          phone: null,
          operatorModule: null,
          dateRange: [], //['2022-01-09','2022-11-03'],
          operatorStart: null,
          operatorEnd: null,
          page: 1,
          pageSize: 10,
        },
        moduleList: [],

        columns: columns,
        list: [],
        total: 0,
      };
    },
    created() {
      // this.getInit();
      this.getList();
    },
    methods: {
      getInit() {
        LoggerApi.selectModuleList().then(data => {
          this.moduleList = data?.list
        })
      },

      // 列表API获取
      getList() {
        this.queryParams.createDateBegin = this.queryParams.dateRange[0] || null
        this.queryParams.createDateEnd = this.queryParams.dateRange[1] || null
        LoggerApi.search(this.queryParams).then(data => {
          // console.log(data)
          this.list = data.list || []
          this.total = data.total || 0
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },

      /**
       * 搜索查询
       */
      handleQuery() {
        this.loading = true
        this.queryParams.page = 1;
        this.getList()
      },

      handleReset() {
        this.$refs['refForm'].resetFields()
      },

    }
  }
</script>
<style lang="less" scoped>
  /deep/ .ant-card-body {
    padding: 18px 18px 8px 18px;
  }

  /deep/ .ant-col-xl-8 {
    padding-left: 0 !important;
  }
</style>
